import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const LetterTemplate = (props) => {
    const history = useHistory();
    return (
        <Wrap>
            <figure>
                <img src={require("../style/img/banner_sem.png")} alt="쌤들의 편지"/>
            </figure>
            <div className="text_box">재회든, 극복이든 부부문제든 소중한 사람과의 관계에서 아픔을 겪는 분들을 보면 안타깝습니다. 왜 이렇게 서로 아프게 할까? 왜 상대에게 단호한 말을 들을 수밖에 없는 말을 들을 수밖에 없는 말을 하지?..</div>
            <button className="to_letter" onClick={() => history.push("/letter")}>
                <span>쌤들의편지 바로가기</span>
                <img src={require("../style/img/arrow_right.svg")} alt="우측 화살표"/>
            </button>
            <button className="to_consult" onClick={() => window.location.href = props.diagnosisLink}>
                <img src={require("../style/img/consult.svg")} alt="상담하기"/>
            </button>
            <div className="talk">
                <img src={require("../style/img/talk_box.svg")} alt="말풍선"/>
                <span>정확한 상황 분석을 위한 사전 무료 대화가 진행됩니다.</span>
            </div>
        </Wrap>
    );
};

const Wrap = styled.div`
    width: 90%;
    margin: 0 auto;

    figure {
        img {
            width: 100%;
        }
    }

    .text_box {
        width: 90%;
        color: #404040;
        font-size: 16px;
        font-family: 'NanumSquare', sans-serif;
        line-height: 22px;
        margin: 36px auto 0;
    }

    .to_letter {
        display: flex;
        margin: 24px auto 0;
        align-items: center;
        justify-content: center;

        span {
            margin-right: 9px;
            color: #404040;
            font-size: 16px;
            font-weight: bold;
            font-family: 'NanumSquare', sans-serif;
        }
    }

    .to_consult {
        margin-top: 45px;
    }

    .talk {
        width: 90%;
        font-family: 'NanumSquareR', sans-serif;
        font-size: 15px;
        color: #5a5a5a;
        display: flex;
        margin: 16px auto 0;
        line-height: 17px;
        img {
            align-self: flex-start;
            margin-right: 9px;
        }
        span {
            font-weight: 500;
        }
    }
`;


export default LetterTemplate;