import React, { Fragment } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

const Nav = inject("CommonStore")(observer((props) => {
    const menuList = [
        {
            id: 1,
            clickHandler: () => {
                if (props.diagHandler) {
                    props.diagHandler();
                }
            },
            link: props.diagnosisLink,
            title: '진단지제출',
        }, {
            id: 2,
            link: 'review',
            title: '재회상담 후기',
            active: props.active === "review" && "active"
        }, {
            id: 3,
            link: 'column',
            title: '재회상담 칼럼',
            active: props.active === "column" && "active"
        }, {
            id: 4,
            link: 'speech',
            title: '너어체 게시판',
            active: props.active === "speech" && "active"
        }
    ];

    return (
        <Wrap>
            <ul>
            {
                menuList.map((menu) => {
                    return (
                        <Fragment key={menu.id}>
                            {
                                menu.id === 1 ? (
                                    <li onClick={menu.clickHandler} className="diagnosis">
                                        <a href={menu.link || "https://form.redate.co.kr"}>
                                            {menu.title}
                                        </a>
                                    </li>
                                ) : (
                                    <li className={menu.active || "item"}>
                                        <a href={`https://main.redate.co.kr/${menu.link}`}>
                                            {menu.title}
                                        </a>
                                    </li>
                                )
                            }
                        </Fragment>
                    )
                })}
            </ul>
        </Wrap>
    );
}));

const Wrap = styled.nav`
    position: fixed;
    top: 80px;
    width: 375px;
    height: 42px;
    background-color: #592dad;
    z-index: 20;
    
    ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            &.diagnosis {
                cursor:pointer
            }

            &.active {
                a {
                    color: #e3e3e3;
                    font-weight: 400;
                }
            }
            
            a, span {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(232, 232, 232, 0.5);
                font-size: 12px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        & {
            width: 100vw;
            margin: 0 auto;
        }
    }
`;

export default Nav;
