import React from 'react';
import styled from 'styled-components';
import {inject, observer} from "mobx-react";
import SwiperCore, {Navigation, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

const SliderTemplate = inject("MainStore")(observer((props) => {
    SwiperCore.use([Navigation, Autoplay]);

    return (
        <>
            <Wrap>
                <div className="main">
                    <img src={require('../style/img/bg.png')} alt="메인" />
                    <Swiper
                        navigation
                        loop={true}
                        autoplay={{
                            delay: 4000,
                        }}
                    >
                        <SwiperSlide>
                            <div className="text_box first">
                                <p>혼자 재회하기 힘든 이유는 </p>
                                <p>해본적이 없기 때문입니다. </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="text_box">
                                <p>나중에 후회하지 말고</p>
                                <p>당장 가능성부터</p>
                                <p>확인해 보세요.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="text_box">
                                <p>재회는 당연하고</p>
                                <p>행복한 연애방법까지</p>
                                <p>꼭 준비되어야합니다.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="text_box">
                                <p>연락하면 거절 될까</p>
                                <p>위축될 필요 없습니다</p>
                                <p>함께 해드릴게요</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="text_box fifth">
                                <p>왜 헤어졌는지 어떻게 잡을지</p>
                                <p>최신 심리학과 자체이론으로</p>
                                <p>풀어드립니다.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="text_box">
                                <p>/ 당신이 싸움/ 이별을</p>
                                <p>더이상 겪지 않도록</p>
                                <p>해결해드리겠습니다.</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </Wrap>
        </>
    )
}));

const Wrap = styled.div`
    width: 100%;
    --swiper-navigation-size: 44px;

    .main {
        width: 100%;
        position: relative;
        overflow: hidden;

        > img {
            width: 100%;
        }

        .swiper-container {
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            width: 100%;
            .swiper-button-prev {
                top:50%;
                transform: translateY(-50%);
                margin-top:0;
            }
            .swiper-button-next {
                top:50%;
                transform: translateY(-50%);
                margin-top:0;
            }
            .swiper-wrapper {
                display: flex;
                align-items: center;
            }
        }

        .text_box {
            text-align: center;
            color: #fff;
            font-size: 27px;
            p {
                font-size: 26px;
                font-weight: 300;
                line-height: 1.68;

                span {
                    color: #fff;
                    font-size: 26px;
                    font-weight: 500;
                }
            }

            &.first {
                p {
                    letter-spacing: -1.26px;
                    line-height: 1.5;
                }
            }

            &.fifth {
                p {
                    font-size:24px;
                }
            }
        }
        
        .diag_total {
            margin-top: 84px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {
                font-size: 19px;
                font-weight: 100;
                color: #dfdfdf;
                margin-left: 13px;
            }

            .account {
                font-size: 29px;
                color: #f1f1f1;
                font-weight: 100;
                margin-right: 9px;
                span {
                    font-size: 59px;
                    color: #fff;
                    font-weight: 200;
                }
            }
        }

        .date_count {
            border-radius: 12px;
            width: 202px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 9px 0 auto;
            background-color: rgba(0,0,0,0.4);
            font-size: 14px;
            color: #dfdfdf;
            font-weight: 100;
        }

        .statistics {
            width: 355px;
            height: 142px;
            background-color: rgba(0,0,0,0.54);
            border-radius: 12px;
            margin: 10px auto 0 auto;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 18px 18px 16px 12px;

            .left {
                width: 100%;

                > .list {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: #fff;
                    font-size: 14px;
                    height: 25px;
                    background-color: rgba(233, 0, 22, 0.2);
                    
                    & + .list {
                        margin-top: 3px;
                    }

                    &:first-child {
                        background-color: rgba(116, 60, 157, 0.5);
                        span {
                            &.num {
                                opacity: 1;
                                font-weight: bold;
                            }

                            &.name {
                                margin-left: 55px;
                                font-weight: bold;
                            }
                        }
                    }

                    span {
                        &.num {
                            margin-left: 5px;
                            opacity: 0.2;
                        }

                        &.name {
                            margin-left: 58px;
                            font-weight: 100;
                        }

                        &.summary {
                            margin-left: auto;
                            margin-right: 10.6px;
                        }
                    }
                }
            }
        }
        
        .swiper-button-prev,
        .swiper-button-next {
          position: absolute;
          top: 80%;
          width: calc(var(--swiper-navigation-size) / 44 * 27);
          height: var(--swiper-navigation-size);
          margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
          z-index: 10;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--swiper-navigation-color, var(--swiper-theme-color));
          &.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            pointer-events: none;
          }
          &:after {
            font-family: swiper-icons;
            font-size: var(--swiper-navigation-size);
            text-transform: none !important;
            letter-spacing: 0;
            text-transform: none;
            font-variant: initial;
            line-height: 1;
          }
        }
        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
          &:after {
            content: 'prev';
            color: #fff;
            font-size: 25px;
          }
          left: 10px;
          right: auto;
        }
        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
          &:after {
            content: 'next';
            color: #fff;
            font-size: 25px;
          }
          right: 10px;
          left: auto;
        }
        
        @each $navColorName, $navColorValue in $colors {
          .swiper-button-prev,
          .swiper-button-next {
            &.swiper-button-#{$navColorName} {
              --swiper-navigation-color: #{$navColorValue};
                /* margin-top: 50px !important; */
            }
          }
        }
    }

    .service {
        width: 90%;
        margin: 47px auto 0;

        > .title {
            font-size: 19px;
            color: #171717;
            font-weight: bold;
        }

        .cont {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            button {
                width: 48%;
                height: 128px;
                border-radius: 8px;
                border: 1px solid #9f9f9f;

                img {
                    display: block;
                    margin: 0 auto;
                }

                span {
                    &.title {
                        display: block;
                        margin-top: 9.8px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    &.details {
                        display: block;
                        font-size: 12px;
                        color: #4e4e4e;
                    }
                }
            }
        }

        .sub_title {
            font-size: 14px;
            margin-top: 22px;
            letter-spacing: -0.84px;
        }

        .one_time {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            figure{
                height: 53px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            button {
                width: 32%;
                height: 127px;
                border-radius: 8px;
                border: 1px solid #9f9f9f;

                span {
                    &.title {
                        display: block;
                        margin-top: 9.8px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    &.details {
                        display: block;
                        font-size: 12px;
                        color: #4e4e4e;
                    }
                }
            }
        }

        .persistant {
            margin-top: 12px;
            button {
                width: 48%;
                height: 128px;
                border-radius: 8px;
                border: 1px solid #9f9f9f;

                img {
                    display: block;
                    margin: 0 auto;
                }

                span {
                    &.title {
                        display: block;
                        margin-top: 9.8px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    &.details {
                        display: block;
                        font-size: 12px;
                        color: #4e4e4e;
                    }
                }
            }
        }

        .management {
            margin-top: 31px;
        }

        .premium {
            margin-top: 28.5px;
            font-size: 13px;
            font-weight: 500;
        }

        .services {
            margin-top: 14.5px;
            display: flex;
            flex-wrap:wrap;
            justify-content: space-between;

            button{
                &:nth-child(3), &:nth-child(4){
                    margin-top:10px;
                }
            }
        }
    }
`;

export default SliderTemplate;
