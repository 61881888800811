const categoryList = [
    {
        id: 0,
        name: '서운함',
    }, {
        id: 1,
        name: '자존감',
    }, {
        id: 2,
        name: '막말',
    }, {
        id: 3,
        name: '경제력',
    }, {
        id: 4,
        name: '잘해주지 못함',
    }, {
        id: 5,
        name: '서운함',
    }, {
        id: 6,
        name: '권태',
    }, {
        id: 7,
        name: '서운함',
    }, {
        id: 8,
        name: '서운함',
    }, {
        id: 9,
        name: '막말',
    }, {
        id: 10,
        name: '경제력',
    }, {
        id: 11,
        name: '권태',
    }, {
        id: 12,
        name: '잘해주지 못함',
    }, {
        id: 13,
        name: '권태',
    }, {
        id: 14,
        name: '경제력',
    }, {
        id: 15,
        name: '파혼',
    }, {
        id: 16,
        name: '잦은 싸움',
    }, {
        id: 17,
        name: '서운함',
    }, {
        id: 18,
        name: '바람',
    }, {
        id: 19,
        name: '잘해주지 못함',
    }, {
        id: 20,
        name: '권태',
    }, {
        id: 21,
        name: '잘해주지 못함',
    }, {
        id: 22,
        name: '서운함',
    }, {
        id: 23,
        name: '잠수',
    }, {
        id: 24,
        name: '잘해주지 못함',
    }, {
        id: 25,
        name: '카톡 차단',
    }, {
        id: 26,
        name: '잘해주지 못함',
    }, {
        id: 27,
        name: '경제력',
    }, {
        id: 28,
        name: '권태',
    }, {
        id: 29,
        name: '서운함',
    }, {
        id: 30,
        name: '경제력',
    }, {
        id: 31,
        name: '서운함',
    }, {
        id: 32,
        name: '막말',
    }, {
        id: 33,
        name: '카톡 차단',
    }, {
        id: 34,
        name: '가족의 반대',
    }, {
        id: 35,
        name: '서운함',
    }, {
        id: 36,
        name: '자존감',
    }, {
        id: 37,
        name: '파혼',
    }, {
        id: 38,
        name: '잦은 싸움',
    }, {
        id: 39,
        name: '서운함',
    }, {
        id: 40,
        name: '권태',
    }, {
        id: 41,
        name: '파혼',
    }, {
        id: 42,
        name: '잦은 싸움',
    }, {
        id: 43,
        name: '바람',
    }, {
        id: 44,
        name: '권태',
    }, {
        id: 45,
        name: '막말',
    }, {
        id: 46,
        name: '카톡 차단',
    }, {
        id: 47,
        name: '폭력',
    }, {
        id: 48,
        name: '권태',
    }, {
        id: 49,
        name: '바람',
    }, {
        id: 50,
        name: '자존감',
    },  {
        id: 51,
        name: '잘해주지 못함',
    }, {
        id: 52,
        name: '잠수',
    }, {
        id: 53,
        name: '잘해주지 못함',
    }, {
        id: 54,
        name: '폭력',
    }, {
        id: 55,
        name: '고시생',
    }
];

export default categoryList;
