import React from 'react';
import styled from 'styled-components';
import { IconNav, Nav, Top } from '.';

const MainHeader = (props) => {
    return(
        <Wrap>
            <Top diagnosisLink={props.diagnosisLink} diagHandler={props.diagHandler} />
            {
                props.isTop ?
                    <>
                        <IconNav diagnosisLink={props.diagnosisLink} diagHandler={props.diagHandler} />
                    </> : <Nav diagnosisLink={props.diagnosisLink} diagHandler={props.diagHandler} />
            }
        </Wrap>
    );
};

const Wrap = styled.header`
`;

export default MainHeader;

