import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {inject} from "mobx-react";

export default
@inject("UserStore")
class PrivateRoute extends React.Component {
    render() {
        const { UserStore, ...restProps } = this.props;

        if (UserStore.currentUser) {
            return (
                <Route exact {...restProps}/>
            );
        } else {
            alert("리데이트 로그인 하신 후 이용해 주시기 바랍니다.");
        }

        return <Redirect to="/login"/>;
    }
}
