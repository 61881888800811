import React from 'react';
import styled from 'styled-components';

const DiagnosisButtonTemplate = (props) => {
    return (
        <>
            <Wrap>
                <button className="possibility" onClick={() => window.location.href = props.diagnosisLink}>
                    <span>재회 가능성을 <span className="bold">진단 해보세요</span></span>
                    <img src={require("../style/img/diagnosis.svg")} alt="진단지"/>
                </button>
            </Wrap>
        </>
    );
};

const Wrap = styled.div`
    width: 100%;

    .possibility {
        background-color: #c1e923;
        border-radius: 12px;
        display: flex;
        width: 344px;
        height: 51px;
        margin: 0 auto;
        box-sizing:border-box;
        padding-left: 20px;
        padding-right: 14px;
        align-items: center;
        justify-content: space-between;

        > span {
            font-family: 'NanumSquare', sans-serif;
            font-size: 20px;
            color: #000;
            font-weight: 400;
            line-height: 1px;

            > span.bold {
                font-weight: 800;
            }
        }
    }
`;

export default DiagnosisButtonTemplate;