import React from 'react';
import styled from 'styled-components';

const Guide = (props) => {
    return(
        <Wrap onClick={() => window.location.href = props.diagnosisLink}>
            <div className="container">
                <div className="text_box">
                    <p>지금 진단지를 작성하고</p>
                    <p>가능성을 확인해보세요</p>
                </div>
                <div className="special">
                    <img src={require("./style/img/arrow_color-right.svg")} alt="화살표" />
                </div>
            </div>
        </Wrap>
    );
};

const Wrap = styled.button`
    position: fixed;
    top: 80px;
    width: 375px;
    height: 65px;
    background-color: #fff;
    z-index: 10;
    overflow: hidden;
    
    > .container  {
        animation: 1s linear 0s slidein;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 23.5px;
        @keyframes slidein {
            from { 
                margin-left:-200px;
            }
            to {
                margin-left: 23.5px;
            }
        }

        > div {
            margin-right: 20px;
        }

        &.special {
            display: flex;
            align-items: center;
        }

        p {
            font-size: 20px;
            line-height: 26px;
            color: #171717;
            text-align: left;

            &:last-child {
                font-weight: bold;
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        & {
            width: 100vw;
            margin: 0 auto;
        }
    }
`;

export default Guide;
