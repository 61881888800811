import React, { useState,useEffect, useRef } from 'react';
import styled from 'styled-components';
import {inject, observer} from "mobx-react";

const ReviewTemplate = inject("MainStore", "CommonStore")(observer((props) => {
    const contentsEl = useRef();
    const [isEmpty, setIsEmpty] = useState(true);

    useEffect(() => {
        props.MainStore.getReviewList(1)
            .then(data => props.MainStore.setReviewList(data));
    },[]);

    useEffect(() => {
        let tem;
        const test = contentsEl.current;

        if (contentsEl.current) {
            tem = setInterval(() => {
                let arrCopy = props.MainStore.reviewList;
                arrCopy = arrCopy.slice(0);
                const firstEl = arrCopy.shift();
                arrCopy.push(firstEl);
                props.MainStore.setReviewList(arrCopy);

                const child = test.firstChild.childNodes;
                for (let i = 1; i < child.length; i++) {
                    child[i].style.marginTop="0px";
                    if (i > 1) {
                        child[0].style.backgroundColor="#fff";
                        child[1].style.backgroundColor="#f5f5f5";
                    }
                }

                test.firstChild.firstChild.style.marginTop = `-141px`;
                setIsEmpty(false);
            }, 3000);
        }

        return () => {
            clearInterval(tem);
        }  
    },[]);

    return (
        <>
            <Wrap isEmpty={isEmpty}>
                <div className="contents" ref={contentsEl}>
                    <a href="http://main.redate.co.kr/review">
                        {
                            props.MainStore.reviewList?.map(review => {
                                return (
                                    <div className="container" key={review.id}>
                                        <div className="category_review">{review.title}</div>
                                        <div className="text_box" dangerouslySetInnerHTML={{__html:review.description}}></div>
                                    </div>
                                )
                            })
                        }
                    </a>
                </div>
            </Wrap>
        </>
   );
}));

const Wrap = styled.div`
    height: 552px;
    overflow: hidden;
    position: relative;

    .contents {
        /* padding-top: 261px; */
        .bullet_temp {
            --swiper-theme-color: #904cef;
            width: 12px;
            height: 12px;
        }
        
        .container {
            background-color: #fff;
            margin: 0 auto;
            height: 140px;
            box-sizing: border-box;
            padding: 30px 15px;
            overflow: hidden;
            transition: margin-top 1s;

            &:first-child {
                background-color: #f5f5f5;
            }

            & + .container {
                border-top: 1px solid #eee;
            }

            &:last-child {
                border-bottom: 1px solid #eee;
            }

            .category_review {
                margin-top: 13px;
                font-size: 17px;
                color: #010101;
                font-weight: bold;
            }
            .stars {
                margin-top: 11px;
                img {
                    & + img {
                        margin-left: 2.3px;
                    }
                }
            }
            .text_box {
                display: -webkit-box;
                margin-top: 13px;
                font-size: 13px;
                color: #2f2f2f;
                line-height: 18px;
                letter-spacing: -0.78px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break:break-all;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2
            }
        }

        .empty_box {
            text-align: center;
            margin-top: 107px;
            margin-bottom: 88px;

            .text_box {
                margin-top: 14px;

                p {
                    &:nth-child(1) {
                        font-size: 24px;
                        color: #424242;
                        span {
                            color: #351da7;
                            font-weight: 500;
                        }
                    }
                    &:nth-child(2) {
                        margin-top: 5px;
                        color: #3c3c3c;
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    @keyframes review {
        to {
            margin-top: -260px;
        }

        from {
            margin-top: 0;
        }
    }
`;

export default ReviewTemplate;
