import React from 'react';
import styled from "styled-components";
import Policy from '../../molecules/Policy/Policy';
import Info from '../../molecules/Info/Info';

const Footer = () => {
    return (
        <Wrap>
            <Policy />
            <Info />
        </Wrap>
    );
};

const Wrap = styled.div`
    
`;


export default Footer;