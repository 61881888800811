import {action, observable, reaction} from "mobx";
import agent from '../agent';

class CommonStore {
    @observable appName = 'ReDate';
    @observable token = window.localStorage.getItem('redate_token');
    @observable appLoaded = false;

    constructor() {
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('redate_token', token);
                } else {
                    window.localStorage.removeItem('redate_token');
                }
            }
        );
    }

    @action setToken(token) {
        this.token = token;
    }

    @action setAppLoaded() {
        this.appLoaded = true;
    }

    getCodeInfo(code) {
        return agent.Common.codeInfo(code);
    }

    getCodeNameInfo(code, name) {
        return agent.Common.codeNameInfo(code, name);
    }
}

export default new CommonStore();
