import React, {Fragment, useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import styled from 'styled-components';
import {
    DiagnosisButtonTemplate,
    DiagnosisTemplate,
    LetterTemplate,
    ReviewTemplate,
    SliderTemplate
} from './templates';
import {createInstance, HackleProvider} from "@hackler/react-sdk";
import { Loading } from '../../util';
import { v4 as uuidv4 } from 'uuid';
import Footer from '../../components/organisms/Footer/Footer';
import { useHistory } from 'react-router-dom';
import MainHeader from '../../components/molecules/Header/MainHeader';

const MainContainer = inject("CommonStore", "MainStore", "MenuStore")(observer((props) => {
    let hackleClient;

    if (process.browser) {
        hackleClient = createInstance("XiSTmWtHb0l9Jg1DAra7TNZ5W16SubeJ");
    }

    const [diagnosisLink, setDiagnosisLink] = useState(`https://form.redate.co.kr`);
    const [menuList, setMenuList] = useState([]);
    const [cookieValue, setCookieValue] = useState('');
    const history = useHistory();
    const [isTop, setIsTop] = useState(true);

    useEffect(() => {
        getMenu();
    },[]);

    useEffect(() => {
        window.addEventListener('scroll', setScrollY);

        return () => {
            window.removeEventListener('scroll', setScrollY);
        };
    },[]);

    const setScrollY = () => {
        if (window.scrollY === 0) {
            setIsTop(true)
        } else {
            setIsTop(false)
        }
    };

    const getMenu = async() => {
        try {
            let menuUrl;
            let variation;
            
            const cookie = getCookie('device_id');

            cookie && setCookieValue(cookie);

            const uniq = cookie?.uniq ?? uuidv4();

            if(!cookie) {
                await props.MenuStore.getVariation(uniq)
                    .then((data) => {
                        variation = data;

                        const value = {
                            variation,
                            uniq
                        };
        
                        setCookie('device_id', value ,1);
                    });
            } else {
                variation = cookie.variation;
            };

            if (variation === "A") {
                menuUrl = await props.MenuStore.getMenuListByTenant(1,1);
            } else if (variation === "B") {
                menuUrl = await props.MenuStore.getMenuListByTenant(2,1);
            } else if (variation === "C") {
                menuUrl = await props.MenuStore.getMenuListByTenant(3,1);
            } else {
                menuUrl = await props.MenuStore.getMenuListByTenant(1,1);
            }

            menuUrl && setMenuList(menuUrl);
        } catch (error) {
            console.log(error)
        }
    };

    const handleTrack = (event) => hackleClient.track(event, {id: getMenu?.uniq});
    
    useEffect(() => {
        const url = new URL(window.location.href);
        const couns = url.searchParams.get('counselor');

        couns && setDiagnosisLink(`https://form.redate.co.kr/?counselor=${couns}`);

        if (cookieValue?.variation === "A") {
            history.replace('/main?tenant=alpha', undefined, { shallow: true })
        } else if (cookieValue?.variation === "B") {
            history.replace('/main?tenant=beta', undefined, { shallow: true })
        } else if (cookieValue?.variation === "C") {
            history.replace('/main?tenant=charlie', undefined, { shallow: true })
        }
    }, [cookieValue?.variation]);

    const useTrack = (e) => {
        const click_diag = e.target.closest(".click_diag");

        if (click_diag && e.currentTarget.contains(click_diag)) {
            handleTrack("click_diag");
        }

        const click_column = e.target.closest(".click_column");

        if (click_column && e.currentTarget.contains(click_column)) {
            handleTrack("click_column");
        }

        const click_video = e.target.closest(".click_video");

        if (click_video && e.currentTarget.contains(click_video)) {
            handleTrack("click_video");
        }
    };

    function setCookie(name, value, day) {
        const date = new Date();
        date.setTime(date.getTime() + day * 24 * 60 * 60 * 1000);
        const temp = JSON.stringify(value);
        document.cookie = name + '=' + temp + ';expires=' + date.toUTCString() + ';path=/';
    };

    function getCookie(name) {
        const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? JSON.parse(value[2]) : null;
    };
    
    return (
        <>
            <MainHeader diagnosisLink={diagnosisLink} diagHandler={handleTrack} isTop={isTop}/>
            <Wrap>
                {
                    hackleClient ? <HackleProvider hackleClient={hackleClient} user={{id: getMenu?.uniq}}>
                        <h1 className="displaynone">재회상담 전문 법인, 리데이트입니다.</h1>
                        {
                            menuList.length !== 0 ? menuList.map((menu) => {
                                if (menu.isActive) {
                                    switch (menu.name) {
                                        case 'slider':
                                            return <SliderTemplate key={menu.id}/>;
                                        case 'review':
                                            return <ReviewTemplate key={menu.id}/>;
                                        case 'letter':
                                            return <LetterTemplate key={menu.id} diagnosisLink={diagnosisLink}/>;
                                        case 'diagnosis' :
                                            return <DiagnosisTemplate key={menu.id}/>;
                                        case 'button' :
                                            return <DiagnosisButtonTemplate key={menu.id} diagnosisLink={diagnosisLink}/>;
                                        default:
                                            return (
                                                <div
                                                    onClick={useTrack}
                                                    className="page_builder"
                                                    key={menu.id}
                                                    style={{width:'100%'}}
                                                    dangerouslySetInnerHTML={{__html:menu.html}}
                                                />
                                            )
                                    }
                                } else {
                                    return <Fragment key={menu.id} />;
                                }
                            }) :
                            <Loading/>
                        }
                    </HackleProvider> : <Loading/>
                }
                <Footer/>
            </Wrap>
        </>
    )
}));

const Wrap = styled.main`
    padding-top: 150px;
    
    .displaynone {
        display: none;
    }

    .page_builder {
        .gjs-cell {
        height: auto;
        }

        img {
        width: 100%;
        }
    }
`;

export default MainContainer;
