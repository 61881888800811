import React from 'react';
import styled from 'styled-components';

const Info = () => {
    return (
        <Wrap>
            <figure>
                <img src={require("./style/img/logo_footer.svg")} alt="푸터로고" />
            </figure>
            <div className="company_info">
                <p>럽디(주) | 서울시 동작구 사당로 26길 38 3층 | 대표이사 : 김나라 </p>
                <p>통신판매업신고 : 제2017-서울동작-0559호</p>
                <p>긴급 유료전화 :  060-604-0242 | 제휴 및 문의 : answer@luvd.co.kr</p>
            </div>
            <div className="copyright">
                <p>COPYRIGHT Ⓒ2020 LUVD. ALL RIGHTS RESERVED.</p>
            </div>
        </Wrap>
    );
};

const Wrap = styled.div`
    background-color: #1d1632;
    padding: 30px 0;

    figure {
        text-align:center;
    }
    
    .company_info {
        margin-top: 20px;
        p {
            font-size: 9px;
            color: #fff;
            text-align: center;
            line-height: 16px;
        }
    }

    .copyright {
        margin-top: 16.8px;

        p {
            font-size: 9px;
            color: #adb5bd;
            text-align: center;
        }
    }
`;

export default Info;