import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ColumnButton from '../../../components/atoms/Button/ColumnButton';
import GoButton from '../../../components/atoms/Button/GoButton';

const ColumnTemplate = () => {
    const history = useHistory();

    return (
        <Wrap>
            <div className="title">인기 칼럼 Top5</div>

            <div className="box_container">
                <ColumnButton />
            </div>

            <div className="button_container">
                <GoButton text="칼럼 더보기" handler={() => history.push('/column')}/>
            </div>
        </Wrap>
    );
};

const Wrap = styled.div`
    padding-top: 41px;
    box-sizing: border-box;
    padding: 41px 16px 0 21px;

    > .title {
        color: #171717;
        font-size: 18px;
        font-weight: bold;
    }

    > .details {
        margin-top: 41px;
    }
`;

export default ColumnTemplate;
