import React from 'react';
import styled from 'styled-components';

const Policy = () => {
    const policyList = [{
        id:1,
        text: '이용약관',
        path: '/policy'
    }, {
        id:2,
        text: '개인정보처리방침',
        path: '/policy/private'
    }, {
        id:3,
        text: '자주하는 질문(FAQ)',
        path: '/policy/faq'
    }];

    return (
        <Wrap>
            {
                policyList.map((list) => {
                    return (
                        <a key={list.id} href={`https://main.redate.co.kr${list.path}`}>{list.text}</a>
                    )
                })
            }
        </Wrap>
    );
};

const Wrap = styled.div`
    background-color: #fff;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        color: #868e96;
        padding: 0 20px;
        height: 100%;

        &:first-child::before {
            display:none;
        }
        
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 10px;
            background-color: #cccccc;
        }
    }
`;

export default Policy;