import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import styled from 'styled-components';
import categoryList from '../../../data/categoryList';

const DiagnosisTemplate = inject("MainStore")(observer((props) => {
    const [nameList, setNameList] = useState([{
        id: 1,
        name: "김",
        count: 10689959,
    }, {
        id: 2,
        name: "이",
        count: 7306828,
    }, {
        id: 3,
        name: "박",
        count: 4192074,
    }, {
        id: 4,
        name: "최",
        count: 2333927,
    }, {
        id: 5,
        name: "정",
        count: 2151879,
    }, {
        id: 6,
        name: "강",
        count: 1176847,
    }, {
        id: 7,
        name: "조",
        count: 1055567,
    }, {
        id: 8,
        name: "윤",
        count: 1020547,
    }, {
        id: 9,
        name: "장",
        count: 992721,
    }, {
        id: 10,
        name: "임",
        count: 823921,
    }, {
        id: 11,
        name: "한",
        count: 773404,
    }, {
        id: 12,
        name: "오",
        count: 763281,
    }, {
        id: 13,
        name: "서",
        count: 751704,
    }, {
        id: 14,
        name: "신",
        count: 741081,
    }, {
        id: 15,
        name: "권",
        count: 705941,
    }, {
        id: 16,
        name: "황",
        count: 697171,
    }, {
        id: 17,
        name: "안",
        count: 685639,
    }, {
        id: 18,
        name: "송",
        count: 683494,
    }, {
        id: 19,
        name: "전",
        count: 559110,
    }, {
        id: 20,
        name: "홍",
        count: 558853,
    }]);

    let result = 0;

    useEffect(() => {
        for (let i=0; i<nameList.length;i++) {
            result += nameList[i].count;
        }
        const temp = nameList.map((item) => ({...item, percentage: (item.count/result).toFixed(5)}));
        setNameList(temp);
    },[]);

    function getRandom(min, max) {
        return Math.floor((Math.random() * (max - min + 1)) + min);
    }

    let diagnosisTime = 0;

    const nameLength = nameList.length;

    return (
        <>
            <Wrap>
                <div className="diag_total">
                </div>

                <div className="date_count">
                    {
                        `${new Date().getFullYear()}년 ${new Date().getMonth() + 1}월 ${new Date().getDate()}일 현재까지`
                    }
                </div>

                <div className="statistics">
                    <div className="cont">
                        <div className="move">
                            {
                                nameList && nameList.slice(0,8).map((diag) => {
                                    const ranNum = getRandom(0, 3);
                                    const ranNameListNum = getRandom(0,nameLength - 1);
                                    const ranCategoryNum = getRandom(0, categoryList.length -1);

                                    diagnosisTime += ranNum;

                                    return (
                                        <div key={diag.id} className="list">
                                            <span className="name">{`${nameList[ranNameListNum].name}**`}</span>
                                            <span className="type">{categoryList[ranCategoryNum].name}</span>
                                            <span className="summary">{diagnosisTime === 0 ? "방금 전 제출" : `${diagnosisTime}분 전 제출`}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Wrap>
        </>
    );
}));

const Wrap = styled.div`
    background-color: #757575;
    z-index: -1;
    .diag_total {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            font-size: 19px;
            font-weight: 100;
            color: #fff;
            margin-left: 13px;
        }

        .account {
            font-size: 29px;
            color: #fff;
            font-weight: 100;
            margin-right: 9px;
            span {
                font-size: 59px;
                color: #fff;
                font-weight: 200;
            }
        }
    }

    .date_count {
        border-radius: 12px;
        width: 202px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 9px 0 auto;
        background-color: #300736;
        font-size: 14px;
        color: #dfdfdf;
        font-weight: 100;
    }

    .statistics {
        width: 355px;
        height: 142px;
        background: #300736;
        border-radius: 12px;
        margin: 10px auto 0 auto;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 12px;
        .cont {
            width: 100%;
            height: 112px;
            margin-top: 18px;
            overflow-y: hidden;

            .move {
                width: 100%;
                animation: banner;
                animation-delay: 2s;
                animation-duration: 5s;
                animation-iteration-count: infinite;

                &:hover {
                    animation-play-state: paused;
                }

                > .list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #fff;
                    font-size: 14px;
                    height: 25px;
                    background-color: rgba(233, 0, 22, 0.2);
                    margin-top: 3px;
                    
                    & + .list {
                    }

                    span {
                        color: #fff;
                        width: 30%;

                        &.name {
                            font-weight: 100;
                            padding-left:10px;
                            width: 20%;
                        }

                        &.type {
                            font-weight: 300;
                        }

                        &.summary {
                            padding-right:10px;
                            font-weight: 100;
                        }
                    }
                }
            }
        }
    }

    @keyframes banner {
        0% {
            margin-top: 0;
        }
        25% {
            margin-top: -28px;
        }
        50% {
            margin-top: -56px;
        }
        75% {
            margin-top: -84px;
        }
        100% {
            margin-top: -112px;
        }
    }
`;

export default DiagnosisTemplate;
