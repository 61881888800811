import {action, observable, reaction} from 'mobx';
import agent from '../agent';

class MainStore {
    @observable menuList = [];
    @observable reviewList = [];
    @observable reviewTypeList = [];
    @observable reviewType = 1;
    @observable diagnosisList = [];
    @observable diagnosisCount = 0;

    constructor() {
        reaction(
            () => this.reviewType,
            reviewType => {
                this.getReviewList(reviewType)
                    .then(list => {
                        this.reviewList = list;
                    });
            }
        );
    }

    @action setMenuList(list) {
        this.menuList = list;
    }

    @action setReviewList(list) {
        this.reviewList = list;
    }

    @action setReviewTypeList(list) {
        this.reviewTypeList = list;
    }

    @action setReviewType(type) {
        this.reviewType = type;
    }

    @action setDiagnosisList(list) {
        this.diagnosisList = list;
    }

    @action setDiagnosisCount(count) {
        this.diagnosisCount = count;
    }

    @action getDiagnosisCount() {
        return agent.Main.getDiagnosisCount();
    }

    @action getDiagnosisList() {
        return agent.Main.getDiagnosisList();
    }

    @action getReviewList(type) {
        return agent.Main.getReviewList(type);
    }
}

export default new MainStore();
