import React from 'react';
import styled from 'styled-components';
import { PageHeader } from 'antd';

const Header = (props) => {
    return(
        <Wrap top={props.top}>
            <PageHeader
                className="page-header"
                onBack={props.backHandler}
                title={props.title}
                backIcon={props.backIcon}
            />
        </Wrap>
    );
};

const Wrap = styled.div`
    margin-top: ${(props) => props.top || "0px"};

    .page-header {
        position: fixed;
        width: 375px;
        height: 72px;
        background-color: #592dad;
        color: #fff !important;
        z-index: 10;

        .ant-page-header-back-button {
                width: 44px;
                height: 44px;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
                cursor: pointer;
            }

        .ant-page-header-heading {
            height: 100%;
            
            .ant-page-header-heading-left {
                position: relative;
                display: flex;
                height: 100%;
                align-items: center;
                font-size: 20px;

                .ant-page-header-heading-title {
                    position: absolute;
                    text-align: center;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .page-header {
            width: 100%;
        }
    }
`;

export default Header;
