import React from 'react';
import styled from 'styled-components';

const GoButton = (props) => {
    return (
        <Button onClick={props.handler}>
            <span className="plus">+</span>
            <span>{props.text}</span>
        </Button>
    );
};

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 51px;
    border: 2px solid #000;
    margin: 26px auto 0 auto;
    span {
        font-size: 14px;

        &.plus {
            font-size: 30px;
            margin-right: 10px;
        }
    }
`;
 
export default GoButton;