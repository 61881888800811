import styled from 'styled-components';
import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

const IconNav = inject("CommonStore")(observer((props) => {
    const menuList = [{
        id: 1,
        clickHandler: () => {
            props.diagHandler();
        },
        link: props.diagnosisLink,
        title: '진단지제출',
        src: './style/img/ico_diagnosis.svg'
    }, {
        id: 2,
        link: 'review',
        title: '재회상담 후기',
        src: './style/img/ico_review.svg'
    }, {
        id: 3,
        link: 'column',
        title: '재회상담 칼럼',
        src: './style/img/ico_column.svg'
    }, {
        id: 4,
        link: 'speech',
        title: '너어체 게시판',
        src: './style/img/ico_speech.svg'
    }];

    return (
        <Wrap>
            <ul>
            {
                menuList.map((menu) => {
                    return (
                        <Fragment key={menu.id}>
                            {
                                menu.id === 1 ? (
                                    <li onClick={menu.clickHandler} className="diagnosis">
                                        <figure>
                                            <img src={require(`${menu.src}`)} alt={menu.title}/>
                                        </figure>
                                        <a href={`http://main.redate.co.kr/${menu.link}`}>{menu.title}</a>
                                    </li>
                                ) : (
                                    <li className={menu.active || "item"}>
                                        <figure>
                                            <img src={require(`${menu.src}`)} alt={menu.title}/>
                                        </figure>
                                        <a href={`http://main.redate.co.kr/${menu.link}`}>{menu.title}</a>
                                    </li>
                                )
                            }
                        </Fragment>
                    )
                })}
            </ul>
        </Wrap>
    );
}));

const Wrap = styled.nav`
    position: fixed;
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    top: 80px;
    height: 70px;

    ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            position: relative;
            width: 100%;
            height: 100%;
            cursor: pointer;

            figure {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 21px;
                margin-top: 8px;

                img {
                    vertical-align: middle;
                }
            }

            a {
                position: absolute;
                top:0;
                left:0;
                display: block;
                color: #ebebeb;
                width: 100%;
                height: 100%;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                line-height: 86px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        & {
            width: 100vw;
            margin: 0 auto;
        }
    }
`;

export default IconNav;
