import React from 'react';
import styled from 'styled-components';

const Top = (props) => {
    return (
        <>
            <Wrap>
                <button className="logo" onClick={() => window.location.href ='https://main.redate.co.kr'}>
                    <img src={require("./style/img/logo.svg")} alt="리데이트 로고"/>
                </button>
                <button className="diagnosis" onClick={() => window.location.href = props.diagnosisLink}>
                    <img src={require("./style/img/top_banner.svg")} alt="진단지"/>
                </button>
            </Wrap>
        </>
    );
};

const Wrap = styled.div`
    position: fixed;
    top:0;
    background-color: #fff;
    width: 375px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index:12;
    box-sizing: border-box;
    padding: 0 20px;

    button {
        &.diagnosis {
            justify-items: flex-end;
        }
    }

    .bar{
        position: absolute;
        right:0;
        top:0;
        width: 100%;
        height: 100vh;

        .bg {
            position: absolute;
            background-color: rgba(0,0,0,0.5);
            width: 100%;
            height: 100vh;
            cursor: pointer;
        }

        .contents {
            position: absolute;
            top:0;
            right:0;
            width: 280px;
            height: 100%;
            box-sizing: border-box;
            background-color: #fff;

            .close {
                position: absolute;
                top: 22.2px;
                left: -28px;
            }

            .info_box {
                background-color: #592dad;
                height: 120px;
                box-sizing: border-box;
                padding: 21px 15px 14px 19.5px;
                .bts {
                    display: flex;
                    justify-content: flex-end;
                    button {
                        position: relative;
                        &.news {
                            margin-right: 7px;
                        }

                        &::after {
                            content:"";
                            position: absolute;
                            left:50%;
                            top:50%;
                            transform: translate(-50%, -50%);
                            width: 130%;
                            height: 130%;
                        }
                    }
                }

                .status {
                    margin-top: 25px;

                    &.login {
                        margin-top: 40px;
                        > .container {
                            button {
                                opacity: 1;
                                background-color: #fff;
                                color: #592dad;
                                font-weight: 500;
                            }
                        }
                    }

                    > .name {
                        color: #fff;
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 5px;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    > .container {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            font-size: 10px;
                            font-weight: 300;
                            color: #fff;
                            letter-spacing: 0.25px;
                            opacity: 0.8;
                        }

                        button {
                            width: 55px;
                            height: 18px;
                            border: 1px solid #fff;
                            border-radius: 8px;
                            letter-spacing: 0.25px;
                            color: #fff;
                            font-size: 10px;
                            font-weight: 300;
                            opacity: 0.8;
                        }
                    }
                }
            }

            .menu_box{
                li {
                    position: relative;
                    border-bottom: 1px solid #f5f5f5;
                    button {
                        width: 100%;
                        height: 39px;
                        text-align: left;
                        box-sizing: border-box;
                        padding-left:20px;
                        color: #2a2a2a;
                        font-size: 14px;
                        letter-spacing: -1.4px;
                    }
                    span {
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 45px;
                        height: 20px;
                        right:20px;
                        top:50%;
                        transform: translateY(-50%);
                        background-color: #592dad;
                        border-radius: 16px;
                        text-align:center;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
            }

            .side_img_box {
                position: absolute;
                bottom: 10vh;
                text-align: center;
                img {
                    width: 90%;
                }
            }
        }
        &.diagnosis {
            margin-right: 15.5px;
        }
    }

    .status {
        font-size: 18px;
        color: #6e6e6e;
        margin-right: 10px;
        white-space: nowrap;
    }

    @media screen and (max-width: 768px) {
        & {
            width: 100vw;
            margin: 0 auto;
        }
    }
`;

export default Top;
