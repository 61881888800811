import React, {useEffect} from 'react';
import styled from 'styled-components';
import "./assets/css/reset.css";
import "./assets/css/page.css";
import "./assets/css/quill.css";
import {Route, Switch, withRouter} from 'react-router-dom';
import Footer from './components/organisms/Footer/Footer';
import {
    MainContainer,
} from './pages';
import {inject, observer} from "mobx-react";
import {RootRoute} from "./util";
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

const App = inject("CommonStore")(observer((props) => {
    useEffect(() => {
        temp();
    },[]);

    const temp = (c=window, l=document, o='https://app.closer.ai/webchat.js', s='B1lqr3', e, r) => {
        if (e) {
            c[e] = c[e] || {};
        }
        r = l.createElement('script');
        s && (o += '?botId=' + s);
        e && (r.setAttribute('data-bind', e));
        r.src = o; r.async = 1;
        l.head.appendChild(r);
    };

    return (
        <Wrap>
            <>
                <Switch>
                    <RootRoute exact path="/"/>
                    <Route path="/" component={MainContainer}/>
                </Switch>
            </>
        </Wrap>
    );
}));

const Wrap = styled.div`
    width: 375px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        & {
            width: 100vw;
            margin: 0 auto;
        }
    }
`;

export default withRouter(App);
