import React from 'react';
import styled from 'styled-components';
import Example from "./style/img/example.png";

const ColumnList = [{
    id: 1,
    title: "무료상담만으로 재회 성공?",
    contents: "헤어진",
    img: Example,
}, {
    id: 2,
    title: "무료상담만으로 재회 성공?",
    contents: "헤어진 연인 생각에 그동안 힘드셨죠?",
    img: Example,
}, {
    id: 3,
    title: "무료상담만으로 재회 성공?",
    contents: "헤어진 연인 생각에 그동안 힘드셨죠? 나랑 헤어지고 ",
    img: Example,
}, {
    id: 4,
    title: "무료상담만으로 재회 성공?",
    contents: "헤어진 연인 생각에 그동안 힘드셨죠? 나랑 헤어지고 싶은정도 였던건지일까여어어어어어어",
    img: Example,
}, {
    id: 5,
    title: "무료상담만으로 재회 성공?",
    contents: "헤어진 연인 생각에 그동안 힘드셨죠?",
    img: Example,
}];

const ColumnButton = () => {
    return(
        <>
            {
                ColumnList.map((list) => {
                    return (
                        <Box key={list.id}>
                            <div className="num">{list.id}</div>
                            <div className="text">
                                <p className="title">{list.title}</p>
                                <p className="contents">{list.contents}</p>
                            </div>
                            <figure className="img_box">
                                <img src={list.img} alt="배경"/>
                            </figure>
                        </Box>
                    )
                })
            }
        </>
        
    );
};

const Box = styled.button`
    display: flex;
    width: 100%;
    height: 107px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    box-sizing: border-box;
    padding: 20px 0 23px 0;

    .num {
        align-self: flex-start;
        font-size: 23px;
        color: #7b589c;
        margin-top: -5px;
    }

    .text {
        .title {
            color: #171717;
            font-size: 15px;
            text-align: left;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-wrap:break-word; 
        }

        .contents {
            margin-top: 7px;
            font-size: 13px;
            color: #797979;
            width: 197px;
            height: 34px;
            text-align: left;
            line-height: 1.38;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-wrap:break-word; 
        }
    }

    .img_box {
        width: 88px;
        img {
            width: 100%;
        }
    }
`;

export default ColumnButton;