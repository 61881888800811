import React from 'react';
import styled from 'styled-components';

export default function Loading() {
    return (
        <Wrap>
            <figure>
                <img src={require("./style/img/re_lod.gif")} alt={"loading"}/>
            </figure>
            <div className="bottom">
                <img className="logo" src={require("./style/img/bi_logo.svg")} alt={"리데이트"}/>
                <p>COPYRIGHT &copy;2020 LUVD. ALL RIGHTS RESERVED.</p>
            </div>
        </Wrap>
    )
};

const Wrap = styled.div`
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 375px;
    height: 100vh;
    background-color: #fff;
    z-index:50;

    figure{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate( -50%, -50%);
        text-align: center;

        
    }

    .bottom {
        position: absolute;
        width: 100%;
        bottom: 5vh;
        left:50%;
        transform: translateX(-50%);
        text-align: center;
        .logo {
            margin-top: 60px;
        }

        p {
            margin-top: 20px;
            font-size: 9px;
            letter-spacing: -0.18px;
            color: #adb5bd;
        }
    }

    
    
    @media screen and (max-width: 768px) {
        & {
            width: 100vw;
            margin: 0 auto;
        }
    }
`;
