import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import CommonStore from './stores/CommonStore';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = `${process.env.REACT_APP_ROOT}/api`;

const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        console.log(err);
    }

    return err;
};

const responseBody = res => res.body ?? res.text;

const tokenPlugin = req => {
    if (CommonStore.token) {
        req.set('Authorization', `Bearer ${CommonStore.token}`);
    }
};

const requests = {
    del: url =>
        superagent
            .del(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    get: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
};

const Main = {
    getDiagnosisCount: () =>
        requests.get(`/main/diagnosis/count`),
    getDiagnosisList: () =>
        requests.get(`/main/diagnosis`),
    getReviewList: (type) =>
        requests.get(`/main/review/type/${type}`),
};

const Common = {
    codeInfo: (code) =>
        requests.get(`/common/code-info/${code}`),
    codeNameInfo: (code, name) =>
        requests.get(`/common/code-info/${code}/${name}`),
};

const Menu = {
    getListByCategory: (category) =>
        requests.get(`/menu/category/${category}`),
    getListByCategoryAndTenant: (tenant_id, category_id) =>
        requests.get(`/menu/tenant/${tenant_id}/category/${category_id}`),
    getVariation: (uniq) => 
        requests.get(`/admin/tenant_info/6/dynamic/${uniq}`),
};

export default {
    Main,
    Common,
    Menu,
};
