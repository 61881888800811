import React from 'react';
import styled from 'styled-components';
import {useHistory} from "react-router-dom";

const SubTop = (props) => {
    const history = useHistory();

    const onBackPage = () => {
        props.backUrl ? history.push(props.backUrl) : history.goBack();
    };

    const onWritePage = () => {
        props.writeUrl ? history.push(props.writeUrl) : console.log("writeUrl 값을 설정해주세요");
    };

    return(
        <Wrap marginTop={props.marginTop}>
            <div className="top_box">
                { props.backIcon &&
                <a className="ico_back" onClick={onBackPage}>
                    <img src={require("./style/img/ico_back.svg")} alt="뒤로가기"/>
                </a>
                }
                <h2>{ props.title }</h2>
                { props.writeIcon &&
                <a className="ico_write" onClick={onWritePage}>
                    <img src={require("./style/img/ico_write.svg")} alt="글쓰기"/>
                </a>
                }
            </div>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    margin: 0 0 15px 0;
    margin-top: ${(props) => props.marginTop || '122px'};
    padding: 0;
    
    > .top_box {
        width: 90%;
        margin: 0 auto;
        padding: 19px 0 11px 0;
        text-align: left;
        border-bottom: 1px solid #dadada;
        
        > .ico_back {
            display: inline-block;
            position: relative;
            width: 41px;
            left: -11px;
            height: 34px;
            vertical-align: bottom;
            cursor: pointer;          

            > img {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-60%, -50%);
            }
        }
                
        > h2 {
            display: inline-block;
            font-size: 30px;
            line-height: 34px;
            font-weight: 500;
            vertical-align: bottom;
        }
        
        > .ico_write {
            float: right;
            margin: 5px 10px 0 0;
            cursor: pointer;
        }        
    }
`;

export default SubTop;
