import {action, observable, reaction} from "mobx";
import agent from '../agent';

class MenuStore {
    @action getMenuList(category) {
        return agent.Menu.getListByCategory(category);
    }

    @action getMenuListByTenant(tenant_id, category_id) {
        return agent.Menu.getListByCategoryAndTenant(tenant_id, category_id);
    }

    getVariation(uniq) {
        return agent.Menu.getVariation(uniq);
    }
}

export default new MenuStore();
